import {Collapse, IconButton, Navbar, Typography} from "@material-tailwind/react";
import {useState} from "react";

const Header = () => {
    const [openNav, setOpenNav] = useState(false);
    
    return (
        <header>
            <Navbar placeholder='' className="px-6 py-3" fullWidth={true}>
                <div className="flex items-center justify-between text-blue-gray-900">
                    <Typography
                        placeholder=''
                        as="a"
                        href="#"
                        variant="h6"
                        className="mr-4 cursor-pointer py-1.5"
                    >
                        oliviamooncostello
                    </Typography>
                    <div className="hidden lg:block">
                        <NavList/>
                    </div>
                    <IconButton placeholder='' variant="text" className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                        ripple={false} onClick={() => setOpenNav(!openNav)}>
                        
                    </IconButton>
                </div>
                <Collapse open={openNav}>
                    <NavList/>
                </Collapse>
            </Navbar>
        </header>
    )
}

const NavList = () => {
    return (
        <ul className="my-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
            <Typography placeholder='' as="li" variant="small" color="blue-gray" className="p-1">
                <a href="/" className="flex items-center hover:text-blue-500 transition-colors">
                    Home
                </a>
            </Typography>
            <Typography placeholder='' as="li" variant="small" color="blue-gray" className="p-1">
                <a href="/" className="flex items-center hover:text-blue-500 transition-colors">
                    About
                </a>
            </Typography>
        </ul>
    );
}

export default Header;
