import {Typography} from "@material-tailwind/react";

const Footer = () => {
    return (
        <footer className="flex w-full flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 border-t border-blue-gray-50 py-6 text-center md:justify-between pt-20 px-5">
            <Typography placeholder='' color="blue-gray">
                &copy; oliviamooncostello
            </Typography>
            <ul className="flex flex-wrap items-center gap-y-2 gap-x-8">
                <li>
                    <Typography placeholder='' as="a" href="#" color="blue-gray"
                                className="transition-colors hover:text-blue-500 focus:text-blue-500">
                        About
                    </Typography>
                </li>
                <li>
                    <Typography placeholder='' as="a" href="#" color="blue-gray"
                                className="transition-colors hover:text-blue-500 focus:text-blue-500">
                        Contact Me
                    </Typography>
                </li>
            </ul>
        </footer>
    )
}

export default Footer;
