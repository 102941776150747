import {Card, Typography} from "@material-tailwind/react";
import artichoke from './images/artichoke.jpg';
import hydrangea from './images/hydrangea.jpg';

interface ContentCardPropsType {
    img: string;
    alt: string;
}

const ContentCard = ({img, alt}: ContentCardPropsType) => {
    return (
        <Card placeholder='' className="relative grid min-h-[30rem] items-end overflow-hidden rounded-xl"
              color="transparent">
            <img src={img} alt={alt} className="absolute inset-0 h-full w-full object-cover object-center"/>
        </Card>
    );
}

const contents: ContentCardPropsType[] = [
    {
        img: artichoke,
        alt: 'Artichoke'
    },
    {
        img: artichoke,
        alt: 'Artichoke'
    },
    {
        img: hydrangea,
        alt: 'Hydrangea'
    },
];

const Body = () => {
    return (
        <main className='pt-20 px-5'>
            <Typography placeholder='' variant="h2" color="blue-gray" className="!text-2xl !leading-snug lg:!text-3xl">
                Welcome
            </Typography>

            <Typography placeholder='' variant="lead" className="mt-2 max-w-lg !text-gray-500">
                This is my page, my home. I hope you find something that speaks to you.
            </Typography>

            <div className="mt-10 grid grid-cols-1 gap-10 lg:grid-cols-3">
                {contents.map(({img, alt}) => (
                    <ContentCard key={alt} img={img} alt={alt}/>
                ))}
            </div>
        </main>
    )
}

export default Body;
