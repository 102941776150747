import React from 'react';
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";

const App = () => {
    return (
        <div className='font-gloock'>
            <Header/>
            <Body/>
            <Footer/>
        </div>
    );
}

export default App;
